.container-terms-conditions {
    margin-left: 0px;
}

.title-container {
    display: flex;
    justify-content: center;
    padding: 2em;
    color: #EDF2F7;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 16.8px;
    text-align: left;
}

.container-terms {
    display: flex;
    justify-content: center;
}

.container-image {
    display: flex;
    justify-content: center;
    padding: 1em;
}

.container-text {
    margin-left: 1rem;
    margin-right: 1rem;
}

.style-title {
    color: #ffffff;
    margin-bottom: 0;
    text-decoration: underline;
}

.style-text {
    color: #EDF2F7;
    font-family: Outfit;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    text-align: justify;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    .container-terms-conditions {
        margin-left: 0px;
    }
}