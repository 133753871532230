.sliding-menu-container {
  position: relative;
  z-index: 1000;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
}

.toggle-button {
  padding: 10px;
  background-color: gray;
  color: white;
}

.sliding-menu {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #141414;
  color: #fff;
}

.sliding-menu.horizontal {
  width: 60%;
  height: 100%;
}

.sliding-menu.vertical {
  width: 100%;
  height: 70%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: red;
  color: white;
}

.menu-button {
  margin: 5px;
  padding: 10px;
  background-color: red;
  color: white;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Abarca todo el ancho */
  padding: 10px;
  border-bottom: 1px solid #1C1C1C;
}

.menu-title {
  flex-grow: 1;
  text-align: center;
  color: #fff;
  margin: 0;
}

.close-icon {
  cursor: pointer;
}