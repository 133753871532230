.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgb(255 255 255 / 85%);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}

.ant-progress-success-bg, .ant-progress-bg {
  position: relative;
  background-color: rgb(56, 161, 105);
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.carousel-container {
  width: 100%;
  height: 400px;
  padding-left: 5em;
  margin-bottom: 6em;
}

.carousel-img {
  width: 100%;
  height: 400px;
}

.container-fight-table {
  background: rgb(20, 20, 20);
  margin: 0em 2em 2em 7em;
  border-radius: 13px
}

.container-fight-table-mobile {
  display: none;
  background: rgb(20, 20, 20);
  margin: 0em 2em 2em 7em;
  border-radius: 13px
}

.container-panel-bet {
  width: 20%;
  margin: 2em 2em 1em 0em;
}

.container-carousel-panel-bet {
  width: 80%;
}

.nav-bar-container-vertical {
  display: none;
}

.container-panel-left-vertical {
  margin: 0em 2em 0em 2em;
  display: none;
  justify-content: center;
}

.container-footer {
  background-color: #781113;
    padding: 72px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  .carousel-container {
    height: 310px;
    padding-left: 0em;
    margin-bottom: 0em;
  }

  .carousel-img {
    width: 100%;
    height: 220px;
  }

  .container-fight-table {
    display: none;
    background: rgb(20, 20, 20);
    margin: 0em 2em 2em 2em;
    border-radius: 13px
  }

  .container-fight-table-mobile {
    display: inline;
    background: rgb(20 20 20 / 0%);
    margin: 0em 0em 2em 0em;
    border-radius: 13px
  }

  .container-panel-bet {
    display: none;
  }

  .container-carousel-panel-bet {
    width: 100%;
  }

  .nav-bar-container-vertical {
    display: inline;
  }

  .container-panel-left-vertical {
    margin: 0em 2em 0em 2em;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
  }

  .container-footer {
    background-color: #781113;
      padding: 72px;
      position: relative;
  }
}