.custom-modal .ant-modal-header {
  background-color: #000;
  /* Fondo negro */
  color: #fff;
  /* Texto blanco */
  border-radius: 4px 4px 0 0;
  /* Esquinas redondeadas */
}

.custom-confirm .ant-modal-title {
  color: #fff;
  /* Cambia este valor al color que desees */
}

.custom-confirm .ant-modal-body {
  color: #fff;
  /* Cambia el color del contenido del cuerpo */
}
/* Cambiar el fondo del botón "Sí" */
.custom-confirm .ant-btn-primary {
  background-color: #4caf50 !important;  /* Verde */
  border-color: #4caf50 !important;      /* Verde */
  color: white !important;               /* Texto blanco */
}

/* Cambiar el fondo del botón "Sí" cuando se pasa el ratón (hover) */
.custom-confirm .ant-btn-primary:hover {
  background-color: #388e3c !important;  /* Verde oscuro */
  border-color: #388e3c !important;      /* Verde oscuro */
}

/* Cambiar el fondo del botón "No" */
.custom-confirm .ant-btn-default {
  background-color: #f44336 !important;  /* Rojo */
  border-color: #f44336 !important;      /* Rojo */
  color: white !important;               /* Texto blanco */
}

/* Cambiar el fondo del botón "No" cuando se pasa el ratón (hover) */
.custom-confirm .ant-btn-default:hover {
  background-color: #d32f2f !important;  /* Rojo oscuro */
  border-color: #d32f2f !important;      /* Rojo oscuro */
}