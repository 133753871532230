.container-last-bets {
    background: rgb(20, 20, 20);
    height: 520px;
    border-radius: 10px;
}

.container-title-bets {
    display: flex;
    justify-content: center;
    padding: 1em;
}

.last-bets {
    justify-content: center;
    background: #1A1A1A;
    margin: 0em 1em 1em 1em;
    border-radius: 10px;
    padding: 3px;
    min-height: 110px;
}

.name-border {
    padding: 5px;
    width: 100%;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid #252525;
}

.cards-title-last {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #EDF2F7;
}

.cards-results {
    width: 50%;
    border-right: 1px solid rgb(37, 37, 37);
    padding: 9px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.btn-historial-apuestas {
    width: 219px;
    height: 48px;
    padding: 0px 24px 0px 24px;
    border-radius: 6px;
    background: #38A169;
    cursor: pointer;
}