.button-add-image,
.button-add-image:hover {
    background-color: rgb(120, 17, 19);
    color: #fff;
    border-radius: 16px;
    padding: 7px 16px 7px 16px;
    margin-top: 1em;
    display: flex;
    align-items: center;
}


.button-add-image:hover {
    background-color: rgb(63, 0, 1);
}