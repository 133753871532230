.container-body-users-panel {
  padding: 2rem 5rem 2rem 5rem;
  background: rgb(14, 14, 14);
}

.container-users-actions {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0em 2em 1em 2em;
  width: 100%;
}

.btn-export-xlsx,
.btn-export-xlsx:hover,
.btn-export-xlsx:focus,
.btn-export-xlsx:active {
  color: rgb(234, 159, 38);
  font-size: 40px;
  margin-left: 20px;
  cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 763px) {
  .container-body-users-panel {
    padding: 2rem 1rem 2rem 1rem;
  }

  .btn-export-xlsx {
    width: auto;
    padding-left: 3px;
    padding-right: 3px;
  }
}