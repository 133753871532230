.container {
  background-color: rgb(14, 14, 14);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.contaier-body {
  width: 1000px;
}

.container-info-fights {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}
