@import '~antd/dist/antd.css';

.container-navbar-left {
    display: flex;
    width: 60px;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    .container-navbar-left {
        display: none;
    }
}