* {
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0E0E0E;
  overflow-x: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  background-color: #0E0E0E;
}

.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}

.ant-tag-close-icon {
  margin-left: 3px;
  color: rgb(255 255 255);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.button-right {
  display: flex;
  justify-content: flex-end;
}

#recaptcha-container {
  margin: 20px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

input.inputBox {
  font-size: 14px;
  margin-top: 8px;
  padding: 5px 8px 4px 8px;
  border-radius: 3px;
  border: 1px solid #3693FF;
}

.calendarWrap {
  display: inline-block;
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}

.msg {
  display: flex;
  padding: 20px 10px 0 20px;
  margin: 20px;
  border-radius: 3000px;
  box-shadow: 0 0 10px rgb(164, 164, 164);
  align-items: center;
}

.sent {
  background-color: #EA9F26;
  color: #1D1D1B;
  border-top-right-radius: 1000px;
  flex-direction: row-reverse;
  padding: 15px 15px 15px 15px;
  text-align: end;
  float: right;
}

.sent-admin {
  background-color: #E81A1A;
  color: #fff;
  border-top-right-radius: 1000px;
  flex-direction: row-reverse;
  padding: 15px 15px 15px 15px;
  text-align: end;
  float: right;
}

.received-admin {
  border: 1px solid lightgray;
  background-color: #E81A1A;
  color: white;
  border-top-left-radius: 1000px;
  padding: 15px 15px 15px 15px;
  float: left;
}

.received {
  border: 1px solid lightgray;
  background-color: #1D1D1B;
  color: white;
  border-top-left-radius: 1000px;
  padding: 15px 15px 15px 15px;
  float: left;
}

.sendMsg {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid lightgray;
  margin-left: -5px;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #fafafa;
}

.msgs {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 500px;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 10px;
  overflow-wrap: break-word;
}

.ant-modal-content {
  border-radius: 20px;
  background-color: #141414;
  box-shadow: none;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal-close {
  color: #fff;
}

.title-modal-payment {
  padding-top: 5px;
  text-align: center;
  font-size: 19px;
  font-weight: 600
}

.span-option-payment {
  font-weight: 600;
}

.container-buttons-footer-modal {
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.item {
  text-align: center;
  align-items: center;
  float: right;
}

.btnSelectPaymentModal,
.btnSelectPaymentModal:hover,
.btnSelectPaymentModal:focus,
.btnSelectPaymentModal:active {
  background: rgb(232, 26, 26);
  border-radius: 10px;
  height: 42px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 17px;
  cursor: pointer;
  margin-left: 2px;
  padding: 12px;
}

.div-scroll {
  height: 5em;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}

.contaner-tabs {
  margin-left: 0;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .div-scroll {
    height: 5em;
    line-height: 1em;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }

  .container-tabs {
    margin-left: 8em;
  }
}