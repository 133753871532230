.container-general {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
}

.containerFooter {
  text-align: center;
  display: flex;
}

.btn-ticket-disable-ed,
.btn-ticket-disable-ed:hover,
.btn-ticket-disable-ed:focus,
.btn-ticket-disable-ed:active {
  font-size: 18px;
  text-align: center;
  color: #d6d6d6;
  height: 38px;
  width: 35px;
  cursor: not-allowed;
  margin: 5px;
  border-radius: 8px;
  border: 1px solid #F1F3F6;
  background-color: #F1F3F6;
}

.btn-ticket-available-ed,
.btn-ticket-available-ed:hover,
.btn-ticket-available-ed:focus,
.btn-ticket-available-ed:active {
  font-size: 18px;
  text-align: center;
  color: #000000;
  height: 38px;
  width: 35px;
  cursor: pointer;
  margin: 5px;
  border-radius: 8px;
  background-color: #F1F3F6;
  border: 1px solid #F1F3F6;
}

.btn-ticket-selected-ed,
.btn-ticket-selected-ed:hover,
.btn-ticket-selected-ed:focus,
.btn-ticket-selected-ed:active {
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
  height: 38px;
  width: 35px;
  cursor: pointer;
  margin: 5px;
  border-radius: 8px;
  background: linear-gradient(75.69deg, rgb(232, 26, 26) 0.52%, rgb(175, 9, 9) 89.84%);
  border: 1px solid rgb(232, 26, 26);
}

.btn-ticket-green,
.btn-ticket-green:hover,
.btn-ticket-green:focus,
.btn-ticket-green:active {
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
  height: 38px;
  width: 35px;
  cursor: pointer;
  margin: 5px;
  border-radius: 8px;
  background-color: #50AF48;
  border: 1px solid #50AF48;
}

.btn-ticket-red,
.btn-ticket-red:hover,
.btn-ticket-red:focus,
.btn-ticket-red:active {
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
  height: 38px;
  width: 35px;
  cursor: pointer;
  margin: 5px;
  border-radius: 8px;
  background-color: #D32F2F;
  border: 1px solid #D32F2F;
}

.container-numbers-event-details-admin {
  flex: 1;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.container-ruleta {
  padding: 1em;
}

.container-buttons-ruleta {
  display: flex;
  width: 70%;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1em;
}

.container-buttons-bet {
  display: grid;
  width: 100%;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1em;
}

.container-bnt-confirm-roulette {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.container-bg-image {
  height: 450px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  padding-top: 130px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.container-steps {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  padding: 10px;
  color: #1A1A1A;
  gap: 20px;
}

.btn-login {
  font-weight: bolder;
  border-radius: 20px;
  border: none;
  background-color: #781113;
  padding: 10px;
  font-size: 15px;
  width: 189px;
  color: #fff;
  cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .container-bg-image {
    height: 400px;
  }

  .container-steps {
    flex-direction: column;
    background-color: #1A1A1A;
    margin-left: 0em;
    margin-right: 0em;
    padding: 2em;
  }

  .container-steps-parrafo {
    display: flex;
    justify-content: center;
  }

  .btn-login {
    background-color: #781113;
    font-size: 12px;
    height: 30px;
    width: 130px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}