.container-body-ads-panel {
  padding: 2rem 5rem 2rem 5rem;
  background: rgb(14, 14, 14);
  min-height: 700px;
}

@media screen and (min-width: 0px) and (max-width: 763px) {
  .container-body-ads-panel {
    padding: 2rem 1rem 2rem 1rem;
  }

}

.btn-add-adds,
.btn-add-adds:hover,
.btn-add-adds:focus,
.btn-add-adds:active {
  background-color: rgb(120, 17, 19);
  border-radius: 13px;
  width: 170px;
  height: 50px;
  border: none;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  cursor: pointer;
}

.btn-add-modal,
.btn-add-modal:hover,
.btn-add-modal:focus,
.btn-add-modal:active {
  background-color: rgb(25 83 52);
  border-radius: 15px;
  width: 100px;
  height: 40px;
  border: none;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  cursor: pointer;
}

.btn-add-cancel,
.btn-add-cancel:hover,
.btn-add-cancel:focus,
.btn-add-cancel:active {
  background-color: rgb(120, 17, 19);
  border-radius: 15px;
  width: 100px;
  height: 40px;
  border: 1px solid #000000;
  color: #fff;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  cursor: pointer;
}
