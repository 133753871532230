.filter-location {
    width: 100%;
    color: #EDF2F7;
    display: flex;
    justify-content: center;
    font-family: Outfit;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    align-items: center;
    border-bottom: 1px solid #ffffff00;
    height: 100%;
    cursor: pointer;
}

.filter-location-selected {
    width: 100%;
    color: #EDF2F7;
    display: flex;
    justify-content: center;
    font-family: Outfit;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    align-items: center;
    border-bottom: 2px solid #38A169;
    height: 100%;
    cursor: pointer;
}

.container-fight-table-filters-horizontal {
    height: 60px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.container-fight-table-filters-vertical {
    display: none;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    .container-fight-table-filters {
        background: rgb(20, 20, 20);
        margin: 0em 2em 2em 7em;
        border-radius: 13px;
    }

    .container-fight-table-filters-horizontal {
        display: none;
    }

    .container-fight-table-filters-vertical {
        height: 60px;
        display: flex;
        background: rgb(20, 20, 20);
        border-radius: 10px 10px 0px 0px;
        justify-content: center;
        width: 100%;
    }
}