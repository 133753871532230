.modal-container-tutorials {
  padding: 2em;
}

.container-tutorials-home {
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
  background-color: rgb(43, 43, 43);
}

.container-title-video {
  width: 100%;
}

.button-add-url {
  width: 25%;
}

.buttons-card-video {
  border-radius: 5px;
  background-color: rgb(229 0 4 / 86%);
  color: #ffffff;
  margin-right: 5px;
  padding: 3px;
}

.containter-flex {
  display: flex;
  align-items: center;
}

.container-tutorials {
  padding: 1rem;
  background-color: rgb(20, 20, 20);
  border-radius: 13px;
}

@media screen and (min-width: 908px) and (max-width: 1214px) {
  .button-add-url {
    width: 36%;
  }
}

@media screen and (min-width: 655px) and (max-width: 907px) {
  .button-add-url {
    width: 43%;
  }
}

@media screen and (min-width: 0px) and (max-width: 655px) {
  .button-add-url {
    width: 70%;
  }
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
  .container-tutorials {
    background-color: rgb(14, 14, 14);
  }
}