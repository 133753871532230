.container-image-event {
    width: 100%;
    height: 85%;
    object-fit: contain;
}

.container-text-next-fight {
    margin-left: 3em;
}
.ant-picker-calendar-full .ant-picker-panel {
    display: block;
    width: 100%;
    text-align: right;
    background: rgb(59 59 59);
    border: 0;
    color: #fff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: auto;
    padding: 0 12px 5px 0;
    line-height: 18px;
    color: #fff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #141414;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #ffffff;
}

.ant-picker-cell {
    padding: 3px 0;
    color: rgb(255 255 255 / 25%);
    cursor: pointer;
}

.ant-picker-cell-in-view {
    color: rgba(255, 255, 255, 0.85);
}

.ant-picker-cell-disabled {
    color: rgb(255 255 255 / 25%);
    pointer-events: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: rgb(56, 161, 105);
}

.box-description {
    box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 4px 7px;
    width: 85%;
    border-radius: 13px;
    margin: 1em;
    padding: 1em;
    background: rgb(33 33 33);
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notes-month {
    font-size: 28px;
    text-align: center;
}

.notes-month section {
    font-size: 28px;
}

.modal-container {
    padding: 2em;
}

.container-image {
    padding: 0em 2em 2em 2em;
    height: 100%;
}

.site-calendar-demo-card {
    width: 100%;
    border-radius: 2px;
    margin: 0em 2em 0em 2em;
}

.container-calendar {
    display: flex;
    justify-content: center;
    padding: 20px 0 10em 0;
    background-color: rgb(14, 14, 14);
    margin-left: 0px;
}

.ant-picker-calendar {
    border-radius: 13px;
    padding: 1em;
    background: rgb(20, 20, 20);
}

.ant-picker-calendar .ant-picker-panel {
    border-radius: 13px;
}

.container-event {
    padding: 1em 4em 3em 1em;
    background-color: rgb(20, 20, 20);
    border-radius: 13px;
}

.card-calendar {
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5); */
    border-radius: 13px;
}

.container-text-calendar {
    justify-content: center;
    display: flex;
    padding: 0px 10px 0px 10px;
}

.content-text {
    margin-left: 10px;
    color: #fff;
}

.ant-avatar>img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: contain;
}

.button-add-event {
    width: 25%;
}

@media screen and (min-width: 908px) and (max-width: 1214px) {
    .button-add-event {
        width: 36%;
    }
}
@media screen and (min-width: 655px) and (max-width: 907px) {
    .button-add-event {
        width: 43%;
    }
}
@media screen and (min-width: 0px) and (max-width: 655px) {
    .button-add-event {
        width: 70%;
    }
}


@media screen and (min-width: 376px) and (max-width: 800px) {

    .container-image-event {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .container-image {
        padding: 0em 2em 2em 2em;
        height: 100%;
    }

    .container-event {
        padding: 1em 1em 3em 1em;
        background-color: rgb(255, 255, 255);
        border-radius: 13px;
    }

    .container-text-calendar {
        justify-content: center;
        display: flex;
        padding: 0px 10px 0px 10px;
    }

    .site-calendar-demo-card {
        width: 100%;
        border-radius: 2px;
        padding: 1em 1em 1em 1em;
    }

    .content-text {
        display: none;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        position: static;
        width: auto;
        overflow-y: unset;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.5715;
        text-align: left;
    }
}

@media screen and (min-width: 801px) {
    .container-image {
        padding: 2em 0em 0em 0em;
        height: 85%;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        position: static;
        width: auto;
        overflow-y: unset;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.5715;
        text-align: left;
    }

}

@media screen and (min-width: 0px) and (max-width: 375px) {
    .site-calendar-demo-card {
        width: 100%;
        border-radius: 2px;
        padding: 1em 1em 1em 1em;
    }
    .container-image {
        padding: 0em 2em 2em 2em;
        height: 80%;
    }

    .box-description {
        box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 4px 7px;
        width: 78%;
        border-radius: 13px;
        margin: 1em;
        padding: 1em;
        background: rgb(33 33 33);
    }
    .container-event {
        padding: 1em 1em 3em 1em;
        background-color: rgb(255, 255, 255);
        border-radius: 13px;
    }
    
    .content-text {
        display: none;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        position: static;
        width: auto;
        overflow-y: unset;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.5715;
        text-align: left;
    }

}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    .container-calendar {
        display: flex;
        justify-content: center;
        padding: 0;
        background-color: rgb(14, 14, 14);
        margin-left: 0px;
    }
    .container-event {
        padding: 1em;
        background-color: rgb(14, 14, 14);
        border-radius: 13px;
    }

    .site-calendar-demo-card {
        width: 100%;
        border-radius: 2px;
        padding: 0;
        margin: 0;
    }

    .container-text-next-fight {
        margin-left: 1em;
    }
}
