.menu-icon-button {
  position: absolute;
  bottom: 25px;
  right: 10px;
  background-color: #141414;
  border: 1px solid #1C1C1C;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu-icon-button:focus {
  outline: none;
}

.container-menu-buttons {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
}

.container-menu-content {
  display: flex;
  flex: 1;
  padding: 10px;
}

/* styles.css */
.iframe-portrait {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%; /* Ajusta según sea necesario */
}

.iframe-landscape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media queries para cambiar las clases según la orientación */
@media (orientation: portrait) {
  .iframe-container iframe {
    height: 60%;
  }
}

@media (orientation: landscape) {
  .iframe-container iframe {
    height: 100%;
    width: 100%;
  }
}
