.reference-container {
  border: 1px solid #ffffff80;
  border-radius: 4px;
  padding: 1em;
  text-align: center;
  color: #fff;
  margin: 1em;
}

.container-general-add-money {
  margin-left: 0px;
  background-color: rgb(14, 14, 14);
  height: 100%;
  padding: 20px;
}

.container-add-money {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 750px;
  background-color: rgb(14, 14, 14);
}

.container-payment-amounts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em 0em 1em 0em;
}

.container-stepper {
  background-color: #2b2b2b;
  width: 100%;
}

.container-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.card-container-add-money {
  width: 500px;
  background: #141414;
  border-radius: 10px;
  padding: 1em;
}

.btn-download-file,
.btn-download-file:hover,
.btn-download-file:focus,
.btn-download-file:active {
  background-color: rgb(179, 84, 38);
  border-radius: 8px;
  width: auto;
  padding: 15px;
  height: 50px;
  border: none;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  line-height: 17px;
  cursor: pointer;
}

.ant-upload-list-picture .ant-upload-list-item-name {
  color: #fff;
}

.ant-upload-list-item-card-actions .anticon {
  color: #fff;
}

.ant-select-selector {
  border: solid 1px #202020 !important;
  border-radius: 5px !important;
  text-align: left;
  justify-content: center;
  align-items: center;
  height: 50px !important;
  color: #fff !important;
  background-color: #2b2b2b !important;
  font-size: 17px !important;
  margin: 0 !important;
  font-family: Outfit;
  line-height: 16.8px;
  text-align: left;

  &:hover {
    border-color: #000000 !important;
  }
}

.ant-select-arrow {
  color: #fff !important;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .container-form {
    width: 100%;
  }

  .container-general-add-money {
    margin-left: 0px;
  }
}

@media screen and (min-width: 0px) and (max-width: 1000px) {

  .container-general-add-money {
    margin-left: 0px;
  }

  .card-container-add-money {
    width: 100%;
    background: #141414;
    border-radius: 10px;
    padding: 1em;
  }

  .container-payment-amounts {
    display: flex;
    justify-content: center;
    margin: 1em;
  }
}