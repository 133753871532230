.container-buttons {
  justify-content: left;
  width: 100%;
}

.fight-info-container {
  width: 100%;
  justify-content: center;
  display: flex;
  background-color: #fff;
  border-radius: 9px;
  padding: 1em;
}

.container-counters {
  width: 100%;
  padding: 0em 2em;
  display: flex;
}

.cards-counters {
  text-align: center;
  width: 50%;
  background: rgb(255, 255, 255);
  border-radius: 13px;
  margin-right: 1em;
  padding: 6px;
  box-shadow: rgb(0, 0, 8) 0px 7px 12px 0px;
}

.horizontal-container-cards {
  display: flex;
  width: 50%;
}

.container-options {
  width: 100%;
  border: 1px solid #1C1C1C;
  background-color: #141414;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px;
  margin-top: 1rem;
  padding: 10px;
}

.container-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .container-buttons {
    display: inline-grid;
    justify-content: left;
  }

  .container-counters {
    width: 100%;
    padding: 0em 0em;
    display: block;
  }

  .cards-counters {
    text-align: center;
    width: 50%;
    background: rgb(255, 255, 255);
    border-radius: 13px;
    margin-bottom: 1em;
    padding: 6px;
    box-shadow: rgb(0, 0, 8) 0px 7px 12px 0px;
  }

  .horizontal-container-cards {
    display: flex;
    width: 100%;
  }
}
