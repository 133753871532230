.table-text {
	font-family: Outfit;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
	
}

.css-1yhpg23-MuiTableCell-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
	border-bottom: 1px solid rgb(224 224 224 / 0%);
    text-align: center;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
}

.button-container-tag {
	position: relative;
	background-color: rgb(120, 17, 19);
	color: #fff;
	padding: 5px 8px 5px 8px;
	border: 0px;
	display: inline-flex;
	justify-content: center;
	overflow: hidden;

}

div.animate-button {
	margin: 2px;
	height: 100%;
	cursor: pointer;
	transform: translate(-0%, -0%);
	color: #ffffff;
	overflow: hidden;
	padding: 8px 10px;
	font-size: 12px;
	text-decoration: none;
	letter-spacing: 2px;
	box-shadow: 0 20px 50px rgb(255 255 255 / 5%);
}

div.animate-button:before {
	position: absolute;
	width: 100%;
	top: 2px;
	left: 2px;
	bottom: 2px;
	content: '';
	background: rgb(141 68 68)
}

div.animate-button span:nth-child(1) {
	position: absolute;
	width: 100%;
	height: 2px;
	top: 0;
	left: 0;
	background: linear-gradient(to right, #ff9305, #f4ff56);
	animation: animate1 2s linear infinite;
}

div.animate-button span:nth-child(2) {
	position: absolute;
	width: 2px;
	height: 100%;
	top: 0;
	right: 0;
	background: linear-gradient(to bottom, #ff9305, #f4ff56);
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}

div.animate-button span:nth-child(3) {
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	bottom: 0;
	background: linear-gradient(to left, #ff9305, #f4ff56);
	animation: animate3 2s linear infinite;
}

div.animate-button span:nth-child(4) {
	position: absolute;
	width: 2px;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(to top, #ff9305, #f4ff56);
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}


div.animate-button-selected {
	margin: 2px;
	cursor: pointer;
	transform: translate(-0%, -0%);
	color: #ffffff;
	overflow: hidden;
	padding: 8px 10px;
	font-size: 12px;
	text-decoration: none;
	letter-spacing: 2px;
	box-shadow: 0 20px 50px rgb(255 255 255 / 5%);
}

div.animate-button-selected:before {
	position: absolute;
	width: 100%;
	top: 2px;
	left: 2px;
	bottom: 2px;
	content: '';
	background:rgb(65, 4, 5);
}

div.animate-button-selected span:nth-child(1) {
	position: absolute;
	width: 100%;
	height: 2px;
	top: 0;
	left: 0;
	background: linear-gradient(to right, #ff9305, #f4ff56);
	animation: animate1 2s linear infinite;
}

div.animate-button-selected span:nth-child(2) {
	position: absolute;
	width: 2px;
	height: 100%;
	top: 0;
	right: 0;
	background: linear-gradient(to bottom, #ff9305, #f4ff56);
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}

div.animate-button-selected span:nth-child(3) {
	position: absolute;
	width: 100%;
	height: 2px;
	left: 0;
	bottom: 0;
	background: linear-gradient(to left, #ff9305, #f4ff56);
	animation: animate3 2s linear infinite;
}

div.animate-button-selected span:nth-child(4) {
	position: absolute;
	width: 2px;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(to top, #ff9305, #f4ff56);
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}


@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(100%);
	}
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}



@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(-100%);
	}
}

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 7px 14px;
	border: 0;
	position: relative;
	overflow: hidden;
	border-radius: 13px;
	transition: all 0.02s;
	font-weight: bold;
	color: rgb(255, 255, 255);
	background-color: #975656;
	z-index: 0;
	box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
	margin-bottom: 5px;
	margin-right: 5px;
  }
  
  .button:hover {
	background: rgb(193, 228, 248);
	color: rgb(33, 0, 85);
  }
  
  .button:active {
	transform: scale(0.97);
  }
  
  .hoverEffect {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
  }
  
  .hoverEffect div {
	background: rgb(222,0,75);
	background: linear-gradient(rgb(175 0 0) 50%, rgb(0 74 247) 50%);
	border-radius: 13px;
	width: 10rem;
	height: 10rem;
	transition: 0.4s;
	filter: blur(12px);
	animation: effect infinite 3s linear;
	opacity: 0.4;
  }
  
  .button:hover .hoverEffect div {
	width: 8rem;
	height: 8rem;
  }
  
  @keyframes effect {
  
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }