.nav-bar-container {
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: center;
}

.nav-bar-image-container {
  display: flex;
  align-items: center;
  width: 33%;
  margin-left: 20px;
}

.icon-display {
  display: none;
}

.nav-bar-balance-container {
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: flex-end;
}
@media screen and (min-width: 0px) and (max-width: 400px) {
  .text-balance {
    font-size: .5rem;

  }

  .text-name {
    font-size: 13px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #000000;
    margin-bottom: 0;
    margin-top: 0;
    min-height: 37px;
    display: flex;
    align-items: center;
  }
}

.image-size {
  height: 27px;
  width: 180px;
}

.icon-size{
  font-size: 35px;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {

  .icon-display {
    display: flex;
  }
  .nav-bar-container {
    display: none;
  }
  .nav-bar-image-container {
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: 10px;
  }

  .nav-bar-balance-container {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: flex-end;
  }

  .image-size {
    height: 21px;
    width: 140px;
  }

  .icon-size{
    font-size: 24px;
  }
}